.partnarship{
    height: 100%; 
    padding:20px 0 20px 0px ;

    .heading{
        font-size: 18px;
        margin-left: 20px;
        font-weight: bolder;
        color: #395b77;
    }
    .card{
        height: 100%;
        // margin: 0 0 20px 20px;
        -webkit-box-shadow: -2px 2px 4px -2px rgba(0, 0, 0, 0.49)!important;
        -moz-box-shadow: -2px 2px 5px -2px rgba(0, 0, 0, 0.49)!important;
        box-shadow:-2px 2px 5px -2px rgba(0, 0, 0, 0.49);
        border-width: 0px !important;
        border-radius: 10px !important;
    }
    .toptx {
        font-size: 12px;
        color: #395b77;
        font-weight: bolder;
        text-transform: uppercase;
        text-align: center;
    }
    .toptx-no-hover {
        font-size: 12px;
        color: #395b77;
        font-weight: bolder;
        text-transform: uppercase;
        text-align: center;
    }
    .toptx:hover {
        color: #4ea9f3;
        width: 100px;
        border-bottom: 3px solid #4ea9f3;
        cursor: pointer;
    }
    .lefttx {
        font-size: 14px;
        font-weight: bolder;
        color: #395b77;
        text-align: center;
        text-transform: uppercase;
        padding:20px;
        margin:0px !important;
    }
    .lefttx:hover {
        text-align: left;
        color: #4ea9f3;
        border-Left: 5px solid #4ea9f3;
        cursor: pointer;
    }
    .txOrange {
        font-size: 12px;
        font-weight: bolder;
        color: #fa8500;
        text-align: center;
        text-transform: uppercase;
        padding:20px;
        margin:0px !important;
    }
    .txRed {
        font-size: 12px;
        font-weight: bolder;
        color: #fa0000;
        text-align: center;
        text-transform: uppercase;
        padding:20px;
        margin:0px !important;
    }
    .txGreen {
        font-size: 12px;
        font-weight: bolder;
        color: #007a1b;
        text-align: center;
        text-transform: uppercase;
        padding:20px;
        margin:0px !important;
    }
    .activeT {
        color: #4ea9f3;
        width: 100px;
        border-bottom: 3px solid #4ea9f3;
    }
    .activeL {
        text-align: left;
        color: #4ea9f3;
        border-Left: 5px solid #4ea9f3;
    }
    .activeN {
        text-align: left;
        border-Left: 5px solid #dfdfdf;
    }
  
  }
  