.mh-100-vh {
  min-height: calc(100vh - 170px);
}

.MuiOutlinedInput-input {
  padding: 12px 14px !important;
}
.form-control {
  width: 100%;
}
.form-label {
  font-size: 19px !important;
  width: 100%;
  margin-bottom: 5px;
}
.form-label2 {
  font-size: 22px !important;
  width: 100%;
  margin-bottom: 5px;
}
.form-label-head {
  font-size: 14px !important;
  width: 100%;
  margin-bottom: 5px;
}
.form-label-head2 {
  font-size: 12px !important;
  width: 100%;
  margin-bottom: 5px;
}
.MuiInputBase-root.MuiInputBase-formControl {
  color: var(--dark);
  font-weight: 700;
}
hr {
  border: 1px dashed #dedede;
  width: 100%;
}
.cust-select {
  position: relative;
  &::before {
    content: ' ';
    padding-left: 6px;
    background-color: var(--info);
    height: 100%;
    position: absolute;
    left: -1.25rem;
    top: 0;
    z-index: 1;
  }
}
.buttonProgress {
  position: 'absolute';
  top: '50%';
  left: '50%';
  margin-top: -12;
  margin-left: -12;
}
.mh-100 {
  min-height: 100px;
}
.mh-150 {
  min-height: 150px;
}
.mh-200 {
  min-height: 200px;
}
.MuiTableCell-root.MuiTableCell-body {
  // @media screen and (min-width: 1200px) {
  //     min-width: 250px;
  // }
  min-width: 120px;
}
.MuiTableCell-root.MuiTableCell-head {
  font-weight: bold;
  white-space: nowrap;
}
.required::after {
  content: ' *';
  font-weight: normal;
  color: red;
}
.btn-round {
  border-radius: 20px !important;
}
.w-30p {
  width: 30px;
}
.image-responsive {
  max-width: 100%;
}
.dtPicker {
  .MuiInput-underline:after,
  .MuiInput-underline:before {
    content: none !important;
  }
  .MuiInputBase-root {
    padding: 6px 0px 6px 12px !important;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    border-radius: 0.2rem !important;

    &:hover {
      border: 1px solid var(--primary) !important;
    }
    &.Mui-focused {
      box-shadow: 0 0.313rem 0.719rem rgba(122, 123, 151, 0.1),
        0 0.156rem 0.125rem rgba(122, 123, 151, 0.06);
      border: 2px solid var(--primary) !important;
      padding: 5px 0px 5px 12px !important;
    }
  }
}
.bg-lblue {
  background-color: #f3fbfe;
}
.mt-12p {
  margin-top: 12px !important;
}
.cust-table {
  .MuiTableCell-root {
    border-bottom: 1px dashed rgba(224, 224, 224, 1) !important;
  }
}
.link {
  text-decoration: underline !important;
}
