.background{
    // background:url('../../assets/images/hero-bg/wave-6.png');
    background-size: cover !important;
    background-repeat: no-repeat !important;
}
.dot{
    width: 8px;
    height: 8px;
    border-radius: 16px;
}
input:-webkit-autofill{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.logo{
    width: auto;
    height: 130px;
}

.dddd{
    border-radius: 4.5rem !important;
    background-color: #395b77 !important;
    width: 10rem !important;
}