.dir-ar-ae {
  direction: rtl;
  text-align: right;
  .dir-ltr {
    direction: ltr !important;
  }
  input {
    direction: rtl;
  }

  .app-sidebar-fixed .app-sidebar {
    right: 0;
    left: unset;
  }
  .MuiButton-startIcon {
    margin-left: 8px;
    margin-right: 0;
    &.MuiButton-iconSizeSmall {
      margin-left: 8px;
    }
  }
  .sidebar-header {
    text-align: right;
  }
  .app-sidebar-fixed .app-content {
    padding-left: 0;
    padding-right: 15rem;
  }
  .app-header-fixed .app-header {
    right: 15rem;
    left: 0;
  }

  .sidebar-navigation ul {
    padding-right: 0;
  }
  .sidebar-navigation ul li > a .sidebar-icon {
    margin-right: 0;
    margin-left: 1.42857rem;
  }
  .sidebar-navigation ul li > a .sidebar-icon-indicator {
    margin-left: unset;
    margin-right: auto;
    transform: rotate(-180deg);
  }
  .sidebar-navigation ul li > a.active .sidebar-icon-indicator {
    transform: rotate(-270deg);
  }
  .sidebar-navigation ul li ul li a::after {
    left: unset;
    right: 12px;
  }
  .avatar-icon {
    margin-left: 1rem;
    margin-right: 0 !important;
  }
  .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel {
    top: 10px;
    left: calc(50% + 7.5px) !important;
    right: calc(-50% + 8px) !important;
  }
  .MuiAutocomplete-inputRoot{
    padding-right: unset !important;
    padding-left: 56px !important;

    .MuiAutocomplete-endAdornment {
      left: 0 !important;
      right: unset !important;
    }
  } 
  .MuiSelect-select.MuiSelect-select {
    padding-right: 0;
    padding-left: 24px;
  }
  .MuiSelect-icon {
    right: unset;
    left: 0;
  }
  .MuiButton-endIcon {
    margin-left: -4px;
    margin-right: 8px;
  }
  .MuiTableCell-root {
    text-align: right;
  }
  .MuiPaginationItem-icon {
    transform: rotate(180deg);
  }
  .cust-select::before {
    left: unset;
    right: -1.25rem;
  }
  .MuiSelect-iconOutlined {
    right: unset;
    left: 7px;
  }
  .MuiFormHelperText-root {
    text-align: right;
  }
  .MuiInputLabel-formControl {
    left: unset;
    right: 0;
  }
  .MuiInputLabel-shrink {
    transform-origin: top right;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(-14px, -6px) scale(0.75);
  }
  .MuiAlert-icon {
    margin-left: 12px;
    margin-right: 0;
  }
  fieldset legend {
    text-align: right;
  }
}
body[dir="rtl"] {
  .MuiSelect-iconOutlined {
    right: unset;
    left: 7px;
  }
  .MuiFormHelperText-root {
    text-align: right;
  }
  
  .MuiAutocomplete-inputRoot{
    &.MuiOutlinedInput-root{
      padding-right: unset !important;
      padding-left: 56px !important;
      .MuiAutocomplete-endAdornment {
          left: 9px !important;
          right: unset !important;
      }
    }
  }
}
@media screen and (max-width: 1099.98px) {
  .dir-ar-ae {
    .app-sidebar {
      position: fixed !important;
      right: -15rem !important;
    }
    .app-sidebar-fixed .app-content {
      padding-left: 0;
      padding-right: 0rem;
    }
    .app-header-fixed .app-header {
      right: 0;
      left: 0;
    }
    .app-sidebar-mobile-open .app-sidebar {
      left: unset !important;
      right: 0 !important;
    }
  }
}
