// Sidebar light

.app-sidebar {
  &--light {
    .app-sidebar--header {
      background: transparent;

      .collapse-sidebar-btn {
        background: transparent;
        color: rgba($primary, 0.8);
        opacity: 1;

        &:hover {
          background: rgba($primary, 0.2);
          color: $primary;
        }
      }
      .expand-sidebar-btn {
        border: $primary solid 2px;
        background: $primary;
        color: $white;
        box-shadow: $box-shadow-sm;
      }
      // &::after {
      //   background: rgba($black, .08);
      // }
    }

    // Logo wrapper

    .app-sidebar-logo {
      &--icon {
        background: $primary;
        box-shadow: 1px 1px 3px rgba($black, 0.2);
      }

      &--text {
        span {
          color: $primary;
          opacity: 0.7;
        }

        b {
          color: $black;
        }
      }

      &:hover {
        color: $black;
      }
    }

    .hamburger-inner,
    .hamburger-inner:after,
    .hamburger-inner:before {
      background: $primary !important;
    }
  }
}
