// Sidebar menu light color scheme
.app-sidebar {
  &--light {
    .sidebar-navigation {
      .sidebar-menu-box {
        background: rgba($primary, 0.03);
      }

      .sidebar-header {
        color: rgba($black, 0.85);
      }

      ul {
        li {
          & > a {
            color: rgba($black, 0.8);

            .sidebar-icon {
              & > * {
                color: $primary;
                fill: $primary;
                // opacity: 0.45;
              }
            }

            .sidebar-icon-indicator {
              opacity: 0.4;
            }
          }

          ul {
            li {
              a {
                &::after {
                  background: rgba($black, 0.08);
                }

                &:hover,
                &.active {
                  &::after {
                    background: rgba($black, 0.12);
                  }
                }
              }
            }
          }
        }

        li > a:hover,
        li > a.active {
          color: $primary;
          fill: $primary;
          background: rgba($primary, 0.05);

          .sidebar-icon-indicator {
            opacity: 0.7;
          }

          .sidebar-icon {
            & > * {
              opacity: 1;
              color: $primary;
              fill: $primary;
            }
          }
        }
      }
    }
  }
}

.app-sidebar-collapsed {
  .app-sidebar--light:not(:hover) {
    .app-sidebar--content {
      .sidebar-navigation {
        .sidebar-header {
          &::after {
            background: rgba($black, 0.15);
          }
        }
      }
    }
  }
}
