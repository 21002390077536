// Core

@import 'core';

// Fonts

@import url('https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap');

// Widgets

@import 'widgets';

// Components

@import 'components';

// Layout Components

@import 'layout-components';

// Layout Blueprints

@import 'layout-blueprints';

@import 'partnership';

@font-face {
  font-family: 'Cairo';
  src: url('./font/Cairo-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'Cairo';
  src: url('./font/Cairo-SemiBold.ttf');
  font-weight: 500;
}
@font-face {
  font-family: 'Cairo';
  src: url('./font/Cairo-Bold.ttf');
  font-weight: 700;
}

.highlight-grid {
  background-color: #fff;
  transition: all 0.8s cubic-bezier(.25,.8,.25,1);

  &:hover {
    box-shadow: 0px 4px 11px 4px rgba(0,0,0,0.19)
  }
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.fw-700 {
  font-weight: 700;
}

.dotted-right {
  border-right: 1px dashed #dedede;
}

.cursor-pointer {
  cursor: pointer;
}

.link-color {
  a {
    color: #0065f7;
  }
  a:hover {
    color: #83b3f8;
  }
}

.unsubscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@font-face {
  font-family: 'Bulgatti';
  src: url('./Bulgattis.ttf');
  font-weight: 400;
}

::-webkit-search-cancel-button,
::-webkit-clear-button {
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000" fill-opacity=".54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  height: 1.5rem;
  margin-right: 0;
  width: 1.5rem;
}

::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000" fill-opacity=".54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
  width: 14px;
  height: 14px;
  cursor: pointer;
  border-radius: 50%;
}
::-webkit-calendar-picker-indicator:hover {
  -webkit-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.04);
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.04);
}

// RESPONSIVE
@media (max-width: 575.98px) {
  // handle dialogs

  .app-content--inner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .MuiDialog-root {
    .MuiDialog-scrollPaper {
      align-items: flex-end;
    }
    .MuiPaper-root {
      width: 100vw;
      margin: 0;
      height: fit-content;
      max-height: 90vh;
      animation: slide-up 0.35s ease-in-out forwards;

      .update-profile-header,
      .create-account-header,
      .ib-application-header {
        padding: 10px 15px !important;
        h5 {
          text-align: left !important;
          margin: 0 !important;
          font-size: 17px;
          &.sub-title {
            font-size: 15px;
            text-transform: lowercase;
          }
        }
      }

      .MuiDialogContent-root {
        padding: 10px 15px;

        > div {
          width: 100% !important;
          margin: 0 !important;
          > div {
            padding: 0 !important;
          }
        }

        &.update-profile-content {
          .declarations {
            .MuiGrid-container {
              display: grid;
              grid-template-columns: 30px 1fr;
            }
            span.MuiCheckbox-root {
              padding-left: 0;
            }
          }
        }
        &.create-account-content {
          .account-type-info {
            width: 100vw;
            margin: 10px -15px;
          }
        }
        &.ib-application-content {
          .MuiGrid-container {
            .MuiFormControl-root {
              margin-bottom: 15px !important;
            }
          }
          .MuiGrid-root.MuiGrid-item {
            padding: 0 12px;
          }
          .font-weight-bold.mb-2.required {
            font-size: 14px;
            line-height: 1.1;
          }
        }
      }
    }
  }
  .my-profile-container {
    .card-body {
      .MuiGrid-spacing-xs-3 > .MuiGrid-item {
        padding: 5px 7px !important;
      }
      .form-label-head {
        margin-bottom: 0 !important;
      }
    }
  }

  .topButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0px;
  }
  .trading-accounts-wrapper {
    .card-body {
      > div {
        margin-top: 0 !important;
      }
      .MuiGrid-item {
        padding: 10px !important;
        > div {
          padding: 0 !important;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          > label {
            width: fit-content !important;
          }
        }
      }
    }
  }
  .change-mt5-password-wrapper {
    .card-body {
      .MuiGrid-spacing-xs-3 {
        margin-top: 0;
        .MuiFormControl-root {
          padding: 0 !important;
        }
      }
    }
  }
  .journey-stepper {
    .MuiStepper-root {
      padding: 15px 5px;
    }
  }
  .MuiCard-root {
    .card-header {
      padding: 10px !important;
      font-size: 18px;
      h5 {
        font-size: 18px;
      }
    }
  }

  .deposit.methods-container {
    .card-body {
      padding: 10px !important;
      > div {
        grid-template-columns: repeat(3, 1fr) !important;
        column-gap: 6px !important;
        row-gap: 6px !important;
      }
    }
  }
  .deposit-form-container {
    .card-body {
      padding: 25px 15px;
      .MuiGrid-spacing-xs-3 > .MuiGrid-item {
        padding-bottom: 0;
      }
      .MuiFormControl-root {
        padding: 0 !important;
      }
    }
  }
  .withdrawal-form-container {
    .MuiFormControl-root {
      padding: 10px 0 !important;
    }
    .form-label-head {
      margin-bottom: 0;
    }
    button[type='submit'] {
      width: 100%;
    }
  }
  .internal-transfer-form {
    .MuiGrid-root.pb-0.MuiGrid-item.MuiGrid-grid-md-6 {
      .MuiFormControl-root.form-control.py-3 {
        padding-bottom: 0 !important;
      }
    }
    button[type='submit'] {
      width: 100% !important;
    }
  }

  .upload-document-container {
    .card-body {
      padding: 15px !important;
      .MuiGrid-container {
        .upload-zone {
          padding: 0;
          padding-bottom: 20px;
          .uploaderDropZone {
            width: 80vw;
            height: 80px;
          }

          h6 {
            margin-bottom: 5px !important;
          }
          &:last-child {
            margin-top: 15px;
          }
        }
      }
      label[for='uploadDoc'] {
        padding: 10px;
      }
    }
    .card-footer {
      flex-direction: column;
      i {
        font-size: 12px;
        margin-bottom: 8px;
      }
      button {
        width: 100%;
      }
    }
  }
  .my-bank-account {
    .card-body {
    }
    .add-bank-button {
      position: absolute;
      top: 0;
      right: 0;
      width: fit-content !important;
      svg {
        &.MuiSvgIcon-fontSizeLarge {
          width: 30px;
        }
        &.d-md-none {
          // width: px;
          font-size: 21px;
        }
      }
    }
  }
  .bankAccountForm {
    .MuiGrid-spacing-xs-3 > .MuiGrid-item {
      padding: 5px !important;
    }
    textarea {
      padding: 0 !important;
    }
    button[type='submit'] {
      width: 100%;
    }
  }

  .activities-container {
    .card-body {
      padding: 0;
      td {
        font-size: 13px !important;
        font-weight: 500 !important;
        padding: 10px 15px;
      }
    }
  }
  .kyc-notice {
    font-size: 12px !important;
    padding-bottom: 10px;
    font-weight: 400 !important;
  }
  .loginCard {
    width: 95%;
    border-radius: 25px;
    padding-top: 15px;
    > img {
      margin: auto !important;
    }
    .card1 {
      padding: 15px;
      border-radius: 25px;
      > div {
        padding: 0 5px !important;
      }
      h4 {
        font-size: 20px;
        & ~ p {
          font-size: 13px !important;
        }
      }
    }
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0);
  }
}
