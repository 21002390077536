body {
    .MuiOutlinedInput-root {
        &.Mui-disabled {
            cursor: not-allowed;
            .MuiOutlinedInput-notchedOutline {
                border-color: $border-color;
            }
            &:hover {
                .MuiOutlinedInput-notchedOutline {
                    border-color: $border-color;
                }
            }
        }
        .MuiOutlinedInput-input {
            &.Mui-disabled {
                cursor: not-allowed;
            }
        }
    }
    .MuiInputLabel-outlined {
        z-index: 1;
        transform: translate(14px, 14px) scale(1);
        pointer-events: none;
    }
}